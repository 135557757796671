import LeftPannel from "./components/LeftPannel/LeftPannel";

function App() {
  return (
    <div>
      <LeftPannel/>
    </div>
  );
}

export default App;
